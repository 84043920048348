<template>
  <div id="app">
    <NavBar :list="navList" name="操作员设置"></NavBar>
    <router-view></router-view>
  </div>

</template>

<script>
// import TopNav from 'muyu-navigation-model/packages/src/NavigationModel/NavigationModel';
// import LeftNav from '@/components/LeftNav';
  
export default {
  name: 'App',

  data() {
    return {
      navList:[
        {
          path: '/opermanSetup/manage',
          name: '操作员管理',
          jurisdictionId: '1103010000',
        },
        {
          path: '/opermanSetup/roleManage',
          name: '角色管理',
          jurisdictionId: '1103010000',
        }
      ]
    }
  },
  created() {
    // this.$store.commit('CHANGEROLEID', JSON.parse(window.localStorage.getItem('loginInfo')).role_id.split(',') || []);
  }
};
</script>

<style lang="scss" scoped>
.header {
  padding: 0;
  height: 68px;
}
/deep/ .el-main{
  padding: 0;
}
/deep/ .el-date-editor{
  vertical-align: top;
}
/deep/ .el-input__inner{
  height: 32px;
  border-radius: 0;
  line-height:32px
}
/deep/ .el-range-separator{
  line-height: 24px;

}
</style>
